import React, { useEffect } from 'react';
import MainPageTemplate from '../components/templates/MainPageTemplate';
import { useRouter } from 'next/router';
import { Heading } from '../components/atoms/typography/Header';
import Button from '../components/atoms/Button';
import { SplitTreatments } from '@splitsoftware/splitio-react';
import { TreatmentWithConfig } from '@splitsoftware/splitio/types/splitio';

const ProveV2: React.FC = () => {
  const router = useRouter();

  useEffect(() => {
    router.push(`${process.env.NEXT_PUBLIC_WEB_APP_URL}/registration`);
  }, [router]);

  return null;
};

const IndexPage: React.FC = () => {
  const router = useRouter();

  const renderContent = (
    proveRegistrationTreatment: TreatmentWithConfig,
    proveRegistrationV2Treatment: TreatmentWithConfig,
  ) => {
    const { treatment: proveRegistration } = proveRegistrationTreatment;
    const { treatment: proveRegistrationV2 } = proveRegistrationV2Treatment;

    if (proveRegistration === 'on' && proveRegistrationV2 === 'on') {
      return <ProveV2 />;
    }

    return (
      <MainPageTemplate>
        <Heading variant='h1' stylesOf='h2' className='mb-2'>
          Do you have an activation code?
        </Heading>
        <div className='flex flex-col space-y-2 my-9'>
          <Button color='blue' className='mb-1' onClick={async () => router.push('/activate')}>
            Yes, I have an activation code
          </Button>
          <Button
            color='lightBlue'
            onClick={async () => router.push(proveRegistration === 'on' ? '/registration' : '/activate')}>
            No, I don’t have one
          </Button>
        </div>
      </MainPageTemplate>
    );
  };

  return (
    <SplitTreatments names={['prove_registration', 'prove_registration_v2']}>
      {({ treatments, isReady }) => {
        return isReady ? (
          renderContent(treatments['prove_registration'], treatments['prove_registration_v2'])
        ) : (
          <MainPageTemplate />
        );
      }}
    </SplitTreatments>
  );
};

export default IndexPage;
